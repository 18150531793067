<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'app-history',
};
</script>

<style></style>
